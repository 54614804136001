export function getTermAndCondition(locale, termsVer, langCd) {
    return import(`./${locale}/${termsVer}/${langCd}/TermAndCondition.vue`);
}
export function getOptionalAgree(locale, termsVer, langCd) {
    return import(`./${locale}/${termsVer}/${langCd}/OptionalAgree.vue`);
}
export function getPrivacyPolicy(locale, termsVer, langCd) {
    return import(`./${locale}/${termsVer}/${langCd}/PrivacyPolicy.vue`);
}
export function getGDPR(locale, termsVer, langCd) {
    return import(`./${locale}/${termsVer}/${langCd}/GDPR.vue`);
}
export function getPrivacyPolicyGen2(locale, termsVer, langCd) {
    return import(`./gen2/${locale}/${termsVer}/${langCd}/PrivacyPolicy.vue`);
}
export function getTermsOfUseGen2(locale, termsVer, langCd) {
    return import(`./gen2/${locale}/${termsVer}/${langCd}/TermsOfUse.vue`);
}
export function getTransferCrossBordersGen2(locale, termsVer, langCd) {
    return import(`./gen2/${locale}/${termsVer}/${langCd}/TransferCrossBorders.vue`);
}
export function getIndividualUsageGen2(locale, termsVer, langCd) {
    return import(`./gen2/${locale}/${termsVer}/${langCd}/IndividualUsage.vue`);
}
export function getDirectMarketingGen2(locale, termsVer, langCd) {
    return import(`./gen2/${locale}/${termsVer}/${langCd}/DirectMarketing.vue`);
}
