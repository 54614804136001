import '@hems/component/resources/scss/main.scss';
import { defineComponent, defineAsyncComponent, reactive, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { getPrivacyPolicyGen2, getTermsOfUseGen2, getTransferCrossBordersGen2, getIndividualUsageGen2, getDirectMarketingGen2, } from '@hems/component/src/termAndCondition';
import { MobileHelper, Helper } from '@hems/util';
const termsVerNZ = process.env.VUE_APP_TERMS_VER_NZ || '';
const PrivacyPolicyNZEN = defineAsyncComponent(() => getPrivacyPolicyGen2('NZ', termsVerNZ, 'en'));
const TermsOfUseNZEN = defineAsyncComponent(() => getTermsOfUseGen2('NZ', termsVerNZ, 'en'));
const TransferCrossBordersNZEN = defineAsyncComponent(() => getTransferCrossBordersGen2('NZ', termsVerNZ, 'en'));
const IndividualUsageNZEN = defineAsyncComponent(() => getIndividualUsageGen2('NZ', termsVerNZ, 'en'));
const DirectMarketingNZEN = defineAsyncComponent(() => getDirectMarketingGen2('NZ', termsVerNZ, 'en'));
export default defineComponent({
    name: 'NZTermsMobileGen2',
    components: {
        PrivacyPolicyNZEN,
        TermsOfUseNZEN,
        TransferCrossBordersNZEN,
        IndividualUsageNZEN,
        DirectMarketingNZEN,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            region: 'NZ',
            lang: 'en',
            termsType: Helper.isNull(route.query.termsType) ? 'PrivacyPolicy' : route.query.termsType,
        });
        const componentName = computed(() => `${state.termsType}${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        // Add Listener
        MobileHelper.addEventListener('loadTheme', (data) => {
            store.dispatch('appCtx/setTheme', data);
        });
        MobileHelper.addEventListener('changeLanguage', (data) => {
            store.dispatch('appCtx/setLangCd', data);
        });
        MobileHelper.addEventListener('loadTermsType', (data) => {
            state.termsType = data;
        });
        function loadTheme() {
            MobileHelper.webToApp({
                action: 'load_theme',
                callback: 'loadTheme',
            });
        }
        function changeLanguage() {
            MobileHelper.webToApp({
                action: 'change_language',
                callback: 'changeLanguage',
            });
        }
        function loadTermsType() {
            MobileHelper.webToApp({
                action: 'load_terms_type',
                callback: 'loadTermsType',
            });
        }
        onMounted(() => {
            try {
                loadTheme();
                changeLanguage();
                loadTermsType();
            }
            catch (e) {
                console.error(e);
            }
        });
        onUnmounted(() => {
            MobileHelper.removeEventListener('loadTheme');
            MobileHelper.removeEventListener('changeLanguage');
            MobileHelper.removeEventListener('loadTermsType');
        });
        return {
            state,
            store,
            componentName,
        };
    },
});
