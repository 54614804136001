import '@hems/component/resources/scss/main.scss';
import { defineComponent, defineAsyncComponent, reactive, onMounted, onUnmounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { getPrivacyPolicyGen2, getTermsOfUseGen2, getTransferCrossBordersGen2, getIndividualUsageGen2, getDirectMarketingGen2, } from '@hems/component/src/termAndCondition';
import { MobileHelper, Helper } from '@hems/util';
const termsVerEU = process.env.VUE_APP_TERMS_VER_EU || '';
const termsVerAU = process.env.VUE_APP_TERMS_VER_AU || '';
const termsVerUS = process.env.VUE_APP_TERMS_VER_US || '';
// Privacy Policy
const PrivacyPolicyEUEN = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'en'));
const PrivacyPolicyEUDE = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'de'));
const PrivacyPolicyEUFR = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'fr'));
const PrivacyPolicyEUIT = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'it'));
const PrivacyPolicyEUKO = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'ko'));
const PrivacyPolicyEUPT = defineAsyncComponent(() => getPrivacyPolicyGen2('EU', termsVerEU, 'pt'));
const PrivacyPolicyAUEN = defineAsyncComponent(() => getPrivacyPolicyGen2('AU', termsVerAU, 'en'));
const PrivacyPolicyUSEN = defineAsyncComponent(() => getPrivacyPolicyGen2('US', termsVerUS, 'en'));
// Terms Of Use
const TermsOfUseEUEN = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'en'));
const TermsOfUseEUDE = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'de'));
const TermsOfUseEUFR = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'fr'));
const TermsOfUseEUIT = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'it'));
const TermsOfUseEUKO = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'ko'));
const TermsOfUseEUPT = defineAsyncComponent(() => getTermsOfUseGen2('EU', termsVerEU, 'pt'));
const TermsOfUseAUEN = defineAsyncComponent(() => getTermsOfUseGen2('AU', termsVerAU, 'en'));
const TermsOfUseUSEN = defineAsyncComponent(() => getTermsOfUseGen2('US', termsVerUS, 'en'));
// Transfer Cross Borders
const TransferCrossBordersEUEN = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'en'));
const TransferCrossBordersEUDE = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'de'));
const TransferCrossBordersEUFR = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'fr'));
const TransferCrossBordersEUIT = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'it'));
const TransferCrossBordersEUKO = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'ko'));
const TransferCrossBordersEUPT = defineAsyncComponent(() => getTransferCrossBordersGen2('EU', termsVerEU, 'pt'));
const TransferCrossBordersAUEN = defineAsyncComponent(() => getTransferCrossBordersGen2('AU', termsVerAU, 'en'));
const TransferCrossBordersUSEN = defineAsyncComponent(() => getTransferCrossBordersGen2('US', termsVerUS, 'en'));
// Individual Usage
const IndividualUsageAUEN = defineAsyncComponent(() => getIndividualUsageGen2('AU', termsVerAU, 'en'));
const IndividualUsageUSEN = defineAsyncComponent(() => getIndividualUsageGen2('US', termsVerUS, 'en'));
// Direct Marketing
const DirectMarketingAUEN = defineAsyncComponent(() => getDirectMarketingGen2('AU', termsVerAU, 'en'));
const DirectMarketingUSEN = defineAsyncComponent(() => getDirectMarketingGen2('US', termsVerUS, 'en'));
export default defineComponent({
    name: 'TermsMobileGen2',
    components: {
        PrivacyPolicyEUEN,
        PrivacyPolicyEUDE,
        PrivacyPolicyEUFR,
        PrivacyPolicyEUIT,
        PrivacyPolicyEUKO,
        PrivacyPolicyEUPT,
        PrivacyPolicyAUEN,
        PrivacyPolicyUSEN,
        TermsOfUseEUEN,
        TermsOfUseEUDE,
        TermsOfUseEUFR,
        TermsOfUseEUIT,
        TermsOfUseEUKO,
        TermsOfUseEUPT,
        TermsOfUseAUEN,
        TermsOfUseUSEN,
        TransferCrossBordersEUEN,
        TransferCrossBordersEUDE,
        TransferCrossBordersEUFR,
        TransferCrossBordersEUIT,
        TransferCrossBordersEUKO,
        TransferCrossBordersEUPT,
        TransferCrossBordersAUEN,
        TransferCrossBordersUSEN,
        IndividualUsageAUEN,
        IndividualUsageUSEN,
        DirectMarketingAUEN,
        DirectMarketingUSEN,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const state = reactive({
            region: store.state.appCtx.envLocale,
            lang: ['AU', 'US'].includes(store.state.appCtx.envLocale) ? 'en' : store.state.appCtx.langCd,
            termsType: Helper.isNull(route.query.termsType) ? 'PrivacyPolicy' : route.query.termsType,
        });
        const componentName = computed(() => `${state.termsType}${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        // Add Listener
        MobileHelper.addEventListener('loadTheme', (data) => {
            store.dispatch('appCtx/setTheme', data);
        });
        MobileHelper.addEventListener('changeLanguage', (data) => {
            store.dispatch('appCtx/setLangCd', data);
        });
        MobileHelper.addEventListener('loadTermsType', (data) => {
            state.termsType = data;
        });
        function loadTheme() {
            MobileHelper.webToApp({
                action: 'load_theme',
                callback: 'loadTheme',
            });
        }
        function changeLanguage() {
            MobileHelper.webToApp({
                action: 'change_language',
                callback: 'changeLanguage',
            });
        }
        function loadTermsType() {
            MobileHelper.webToApp({
                action: 'load_terms_type',
                callback: 'loadTermsType',
            });
        }
        onMounted(() => {
            try {
                loadTheme();
                changeLanguage();
                loadTermsType();
            }
            catch (e) {
                console.error(e);
            }
        });
        onUnmounted(() => {
            MobileHelper.removeEventListener('loadTheme');
            MobileHelper.removeEventListener('changeLanguage');
            MobileHelper.removeEventListener('loadTermsType');
        });
        return {
            state,
            store,
            componentName,
        };
    },
});
